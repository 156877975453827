import Head from 'next/head';
import '../css/CollapsibleTable.css';
import '../css/FragmentManager.css';
import '../css/InfoDisplay.css';
import '../css/Sketcher.css';
import '../css/TestConsole.css';
import '../css/topbar.css';
import '../css/tour.css';
import '../css/UIComponents.css';
import '../css/ux2_2dviewer.css';

/**
 * Custom App for Next.js.
 * The Component prop is the active page, so whenever you navigate between
 * routes, Component will change to the new page. Therefore, any props you send to
 * Component will be received by the page.
 *
 * PageWrapperProps are used to pass along the emotionCache from _document.jsx in next.js case.
 * (See https://github.com/mui/material-ui/blob/master/examples/nextjs/)
 *
 * The default export is required in a new `pages/_app.js` file.
 */
export default function MyApp({ Component, pageProps, PageWrapperProps }) {
    return (
        <>
            <Head><title>Boltzmann Maps</title></Head>
            <Component {...pageProps} PageWrapperProps={PageWrapperProps} />
        </>
    );
}
